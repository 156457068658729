<template>
  <div class="problem">
    <div class="contanier">
      <h2>常问问题</h2>
      <el-tabs tab-position="left" @tab-click="handleChange">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in list"
          :key="index"
          ><div class="content">
            <h3>{{ item.name }}</h3>
            <el-collapse accordion v-model="activeNames" @change="handleChange">
              <el-collapse-item
                :name="index"
                v-for="(item, index) in infolist"
                :key="index"
              >
                <template slot="title">
                  <el-image
                    v-if="activeNames == index"
                    style="width: 20px; height: 20px;margin-right:20px"
                    :src="require('@/util/images/index/icon_bluejt_down.png')"
                    fit="contain"
                  ></el-image>
                  <el-image
                    v-if="activeNames != index"
                    style="width: 20px; height: 20px;margin-right:20px "
                    :src="require('@/util/images/index/icon_bluejt_right.png')"
                    fit="contain"
                  ></el-image>
                  {{ item.name }}
                </template>
                <div v-html="item.content"></div>
              </el-collapse-item>
            </el-collapse>
            <h4>还有问题吗？ <br />通过联系表格向我们发送消息来与我们联系。</h4>
          </div></el-tab-pane
        >
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { articlelist, articlelist1 } from "../../api/userapi";
export default {
  data() {
    return {
      list: "",
      activeNames: "0",
      infolist: []
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      articlelist({ pid: 2 })
        .then(res => {
          this.list = res.list;
          this.handleChange({ index: 0 });
        })
        .catch();
    },
    handleChange(val) {
      const index = val.index;
      articlelist1({ type_id: this.list[index].id }).then(res => {
        if (res.code === 1) {
          this.infolist = res.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
h3 {
  font-size: 30px;
  color: #0383e4;
  line-height: 30px;
  margin-bottom: 30px;
}
h4 {
  font-size: 14px;
  color: #000000;
  margin: 30px 0 100px 0;
  margin-top: 50px;
}

/deep/.el-icon-arrow-right:before {
  content: "";
}
/deep/.el-collapse-item__wrap {
  background: #ffffff;
  border: 1px solid #d9dde1;
  padding: 20px;
}
/deep/.el-collapse-item__header {
  height: 60px;
  background: #f5f7f9;
  border: 1px solid #d9dde1;
  padding: 0 20px;
}
h2 {
  position: absolute;
  top: 30px;
  left: 20px;
  font-size: 18px;
  width: 230px;
  color: #000000;
  line-height: 24px;
}
.problem {
  background-color: #f5f7f9;
}
.content {
  // width: 880px;
  background-color: #fff;
  padding: 50px;
  min-height: 700px;
  border-left: 1px solid #d9dde1;
}
/deep/.el-tabs__header {
  width: 230px;
}
/deep/.el-tabs__item {
  text-align: left !important;
  border-bottom: 1px solid #d9dde1;
}
/deep/.el-tabs--left .el-tabs__header.is-left {
  margin-right: 0;
  padding-top: 120px;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
/deep/.el-tabs__active-bar {
  background-color: transparent;
}
</style>
